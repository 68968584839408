<template>
    <div class="voucher-file-handler-container">
        <div class="grid grid-cols-2 gap-2 mb-4">
            <el-upload class="w-full" ref="upload" action="#" :show-file-list="false" :auto-upload="false" multiple :on-change="filesSelected" drag>
                <div class="h-full flex flex-col justify-center items-center">
                    <i class="fad fa-cloud-arrow-up text-3xl" />
                    <p class="text-xs">Drop file here or click to upload</p>
                </div>
            </el-upload>

            <div>
                <div class="flex justify-between" v-for="document in filesData" :key="document.uid">
                    <p @click="changeFilePreview(document)" class="p-1 mb-2 mr-1 flex-1 text-xs rounded-lg cursor-pointer hover:bg-blue-100">
                        <i v-if="document.raw.type === 'image/jpeg' || document.raw.type === 'image/png'" class="fa-thin fa-file-image text-2xl mr-1" />
                        <i v-if="document.raw.type === 'application/pdf'" class="fa-thin fa-file-pdf text-2xl mr-1" />
                        {{ document.name }}
                    </p>
                    <span @click="deleteFile(document.uid)" class="flex justify-center items-center mt-2 w-4 h-4 bg-red-400 text-white text-xs rounded-md cursor-pointer">
                        <i class="fa-solid fa-x" />
                    </span>
                </div>
            </div>
        </div>
        <img v-if="selectedDocument" :src="url(selectedDocument.raw)" alt="" />
        <object v-if="selectedDocument && selectedDocument.raw.type === 'application/pdf'" :data="url(selectedDocument.raw)" type="application/pdf" width="100%" height="600" />
    </div>
</template>
<script>
export default {
    data() {
        return {
            filesData: [],
            selectedDocument: null,
        };
    },

    methods: {
        url(file) {
            return URL.createObjectURL(file);
        },

        changeFilePreview(document) {
            this.selectedDocument = document;
        },

        filesSelected(file) {
            this.filesData.push(file);
            this.changeFilePreview(file);
            this.$emit("filesSelected", this.filesData);
        },

        deleteFile(uid) {
            if (this.selectedDocument.uid === this.filesData.filter(item => item.uid === uid)[0].uid) {
                this.selectedDocument = null;
            }

            this.filesData = this.filesData.filter(item => item.uid !== uid);

            if (this.filesData.length) {
                this.changeFilePreview(this.filesData[0]);
            }

            this.$emit("filesSelected", this.filesData);
        },

        reset() {
            this.filesData = [];
            this.selectedDocument = null;
        },
    },
};
</script>
<style>
.voucher-file-handler-container .el-upload-dragger,
.voucher-file-handler-container .el-upload {
    width: 100%;
}
.el-upload-dragger {
    height: 80px;
}
</style>
